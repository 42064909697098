
import { styled } from '@material-ui/core/styles';
import {
    Box, Button, IconButton, Stack,
    Paper, OutlinedInput, Typography,
    TablePagination,
    Tab, Tooltip, Toolbar, InputAdornment,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';

const RootStyle = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    // padding: theme.spacing(0, 1, 0, 3),
    paddingRight: '0 !important',
    margin: 0,
    padding: '0px !important',

}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    // width: { lg: '240px', md: '240px', sm: '200px', xs: '150px' },
    width: 55,
    // width: 180,
    height: 40,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    // border: '1px solid #fb5d2e',
    '&.Mui-focused': { width: 250, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
        border: `none`
    },
    input: {
        '&::placeholder': {
            // fontStyle: 'italic',
            color: '#fb5d2e'
        },
    },
}));

const SearchBox = (props) => {

    const {
        filterName,
        handleFilterByName,
        placeholder,
        sx
    } = props

    // const handleSearch = (e) => {
    //     handleFilterByName(e)
    // }
    console.log('filterName', filterName)
    return <>
        <RootStyle>
            <SearchStyle
                value={filterName}
                onChange={handleFilterByName}
                placeholder={placeholder}
                startAdornment={
                    <>
                        <InputAdornment position="start">
                            <Box
                                component={Icon}
                                icon={searchFill}
                                sx={{ ...sx }}
                            // sx={{ color: '#5F6368', fontSize: '20px' }}
                            />
                        </InputAdornment>
                    </>
                }
            />
        </RootStyle>
    </>
}

export const MobileSearchBox = (props) => {

    const {
        filterName,
        handleFilterByName,
        placeholder,
        sx
    } = props
    const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
        // width: { lg: '240px', md: '240px', sm: '200px', xs: '150px' },
        width: 150,
        height: 40,
        transition: theme.transitions.create(['box-shadow', 'width'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter
        }),
        // border: '1px solid #fb5d2e',
        '&.Mui-focused': { width: 150, boxShadow: theme.customShadows.z8 },
        '& fieldset': {
            borderWidth: `1px !important`,
            borderColor: `${theme.palette.grey[500_32]} !important`,
            border: `none`
        },
        input: {
            '&::placeholder': {
                // fontStyle: 'italic',
                color: '#fb5d2e'
            },
        },
    }));

    console.log('filterName', filterName)
    return <>
        <RootStyle>
            <SearchStyle
                value={filterName}
                onChange={handleFilterByName}
                placeholder={placeholder}
                startAdornment={
                    <>
                        <InputAdornment position="start">
                            <Box
                                component={Icon}
                                icon={searchFill}
                                sx={{ ...sx }}
                            // sx={{ color: '#5F6368', fontSize: '20px' }}
                            />
                        </InputAdornment>
                    </>
                }
            />
        </RootStyle>
    </>
}
export default SearchBox